import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bgGradient: "linear(to-t, cyan.400, cyan.100)",
      },
    },
  },
  fonts: {
    body: "IBM Plex Sans",
    heading: "IBM Plex Sans",
  },
});

export default theme;
